@use '../abstracts' as *;
@use 'sass:color';

.ks-messages--temp,
.ks-messages {
  .ks-message {
    border: 3px solid;
    display: grid;
    grid: auto-flow / auto 1fr 46px;
    grid-gap: $ks-spacer-3;
    line-height: 1.65rem;
    margin-block: $ks-spacer-2;
    padding-inline: 0;
    place-items: center start;

    span {
      padding-block: $ks-spacer-2;
    }

    &__icon-wrapper {
      color: white;
      display: grid;
      font-size: $ks-font-size-7;
      height: 100%;
      padding: $ks-spacer-2 + $ks-spacer-1;
      place-items: center;
    }

    &--danger {
      border-color: $color-subsegment-agriculture;

      .ks-message__icon-wrapper {
        background: $color-subsegment-agriculture
      }
    }

    &--warning {
      border-color: $color-sun-yellow;

      .ks-message__icon-wrapper {
        background: $color-sun-yellow
      }
    }
  }

  &--compact {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: fit-content;

    &:has(.ks-message--compact:hover) .ks-message--compact:not(:hover) {
      opacity: 0.3;
    }

    .ks-message--compact {
      padding-block: calc($ks-spacer-1 / 2);
      position: relative;
      transition: 250ms opacity ease;

      &-danger {
        --color: rgb(205, 16, 19); // $color-subsegment-agriculture
      }

      &-warning {
        --color: rgb(252, 194, 67); // $color-sun-yellow
      }

      i.icon {
        color: var(--color);
        font-size: $ks-spacer-4;
        font-weight: $font-weight-bold !important;
      }

      &::before,
      &::after {
        --opacity: 0;
        left: 50%;
        opacity: var(--opacity);
        position: absolute;
        top: -.25rem;
        transform: translateX(calc(-100% - $ks-spacer-3 - $ks-spacer-1)) translateY(-12%);
        transform-origin: right center;
        transition: 125ms transform ease, 125ms opacity ease;
        visibility: hidden;
      }

      &::before {
        background: $color-white;
        border: 3px solid var(--color);
        color: $color-black;
        content: attr(data-tooltip);
        padding: .75rem 1.5rem;
        width: clamp(355px, 575px, calc(100vw - 355px));
      }

      &:hover::before,
      &:hover::after {
        --opacity: 1;
        transform: translateX(calc(-100% - $ks-spacer-4)) translateY(-12%);
        visibility: visible;
      }
    }
  }

  &--cozy {
    margin-block: $ks-spacer-4;

    .ks-message {
      margin-block: $ks-spacer-4;
    }
  }

  &--in-delivery {
    margin-block-end: $ks-spacer-6;
  }
}
