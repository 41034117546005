@use '../../abstracts' as *;

@mixin priceTotals($valueFontSize: $ks-font-size-7) {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  padding-inline-end: $ks-spacer-8;

  @include respond-below(lg) {
    padding-inline-end: $ks-spacer-4;
  }

  @include respond-below(md) {
    padding-inline-end: $ks-spacer-3;
  }

  &__value {
    font-size: $valueFontSize;
  }

  &__label {
    color: $color-dark-grey;
    font-size: $ks-font-size-3;
  }
}

.ks-checkout-order {
  margin-block-end: $ks-spacer-8;
  margin-block-start: $ks-spacer-4;

  &s {
    margin-block: $ks-spacer-3;

    &--readonly {
      input, textarea {
        @include readonly();
      }
    }
  }

  &__header {
    $header: &;
    background-color: $color-lighter-grey;

    &-title {
      background-color: $color-brand-primary;
      color: $color-white;
      min-height: $ks-spacer-5;
      padding: $ks-spacer-2 $ks-spacer-4;
      text-transform: uppercase;
    }

    &-container {
      display: grid;
      grid: auto-flow / minmax(70px, max-content) repeat(2, 1.5fr) repeat(2, 1fr);
      grid-gap: $ks-spacer-8;
      padding: $ks-spacer-3 $ks-spacer-5;

      @include respond-below(md) {
        & {
          grid: auto-flow/minmax(50px, max-content) repeat(2, 1fr);
          grid-column-gap: $ks-spacer-4;
          grid-row-gap: $ks-spacer-2;

          #{$header}-item:nth-of-type(3) {
            grid-area: 2 / 2 / 3 / 3;
          }

          #{$header}-item:nth-of-type(4) {
            grid-area: 2 / 3 / 3 / 4;
          }
        }
      }

      #{$header}-item {
        &-label {
          color: $color-dark-grey;
          text-transform: uppercase;
        }

        &-value {
          color: $color-black;
          font-weight: $font-weight-bold;
        }
      }
    }

    &-inputs {
      column-gap: $ks-spacer-4;
      display: flex;
      flex-direction: column;
      grid-gap: $ks-spacer-4;
      padding: $ks-spacer-5;

      #{$header}-input {
        $columnWidth: 270px;

        display: grid;
        grid: auto-flow / min($columnWidth, 100%) min(400px, calc(100% - calc($columnWidth + $ks-spacer-3)));
        grid-column-gap: $ks-spacer-3;

        @include respond-below(sm) {
          display: flex;
          flex-direction: column;
        }

        &-label {
          color: $color-dark-grey;
          font-size: $ks-font-size-4;
          text-transform: uppercase;
          font-weight: normal;
        }

        input {
          height: fit-content;
          width: clamp(90px, 100%, 300px);
        }

        // Reset
        textarea {
          min-height: initial;
        }
      }
    }
  }

  &-total {
    @include priceTotals();
  }
}

.ks-checkout-total {
  @include priceTotals($valueFontSize: $ks-font-size-8);

  border-top: $ks-spacer-5 solid $color-brand-primary;
  padding-block: $ks-spacer-3;
}
