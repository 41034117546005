@use 'sass:color';

$color-brand-primary: rgb(0, 58, 120);
$color-brand-secondary: rgb(0, 161, 58);
$color-brand-tertiary: rgb(0, 94, 161);
$color-brand-quaternary: rgb(191, 195, 198);
$color-brand-quinary: rgb(126, 130, 133);
$color-bluish: rgb(23, 144, 208);
$color-soft-blue: rgb(92, 172, 224);
$color-light-blue: rgb(159, 210, 243);
$color-pale-sky-blue: rgb(201, 232, 251);
$color-sun-yellow: rgb(252, 194, 67);
$color-subsegment-agriculture: rgb(205, 16, 19);
$color-subsegment-food: rgb(0, 58, 120);
$color-subsegment-feed: rgb(80, 174, 47);
$color-subsegment-pharma: rgb(121, 46, 121);
$color-subsegment-industry: rgb(112, 117, 119);
$color-subsegment-water-softenig: rgb(0, 158, 227);
$color-subsegment-waste-management: rgb(0, 171, 164);
$color-subsegment-de-icing: rgb(23, 140, 165);
$color-white: rgb(255, 255, 255);
$color-lightest-grey: rgb(249.9, 249.9, 249.9);
$color-lighter-grey: rgb(244.8, 244.8, 244.8);
$color-light-grey: rgb(229.5, 229.5, 229.5);
$color-medium-grey: rgb(204, 204, 204);
$color-grey: rgb(153, 153, 153);
$color-dark-grey: rgb(102, 102, 102);
$color-anthrazit: rgb(51, 51, 51);
$color-black: rgb(0, 0, 0);
$color-border: $color-medium-grey;
$color-hover: $color-bluish;
$color-hover-background: rgba(0, 94, 161, 0.1);
$color-disabled-background: color.adjust($color-white, $lightness: -4%);
$color-disabled-text: color.adjust($color-grey, $lightness: -4%);
