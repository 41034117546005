@use '../../../abstracts' as *;

.ks-delivery-transport-card-list {
  display: flex;
  flex-wrap: wrap;
  gap: $ks-spacer-4;
  padding: $ks-spacer-4 0;

  @include respond-below(md) {
    justify-content: flex-start;
  }
}
