@use '../../../abstracts' as *;

$shared-breakpoint: lg;

.consignment-list {
  margin: $ks-spacer-4 0;

  &__headline#transport {
    color: $color-dark-grey;
    font-weight: $font-weight-bold;
    margin-bottom: $ks-spacer-1;
    margin-left: 126px;

    @include respond-below($shared-breakpoint) {
      margin: 0;
    }
  }

  &-item {
    display: grid;
    grid-template-columns: 235px repeat(4, 250.5px);
    margin-left: 126px;
    padding: $ks-spacer-3 0 $ks-spacer-3 $ks-spacer-3;
    place-items: center;

    @include respond-below($shared-breakpoint) {
      grid-row-gap: $ks-spacer-3;
      grid-template-columns: repeat(2, 1fr) 130px;
      grid-template-rows: repeat(2, min-content);
      margin: $ks-spacer-2 0;
    }

    @include respond-below(sm) {
      grid-template-columns: 1fr 130px;
      grid-template-rows: repeat(4, min-content);
    }

    &:nth-child(even) {
      background-color: #f2f2f2;
    }

    &:nth-child(odd) {
      background-color: $color-white;
    }

    > div {
      padding: 0 10px;
      width: 100%;

      @include respond-below(sm) {
        grid-column: 1 / 2;
      }
    }

    &__delivery {
      display: flex;
      flex-direction: column;
      row-gap: $ks-spacer-3;
    }

    &__status {
      display: flex;
      flex-direction: column;
      row-gap: $ks-spacer-3;
    }

    &__shipping {
      display: flex;
      flex-direction: column;
      row-gap: $ks-spacer-3;

      @include respond-below($shared-breakpoint) {
        row-gap: 0;
      }
    }

    &__quantity {
      display: flex;
      justify-content: center;

      @include respond-below($shared-breakpoint) {
        justify-content: flex-start;
      }
    }

    &__label {
      color: $color-dark-grey;
      font-weight: $font-weight-bold;
    }

    &__download#download {
      display: flex;
      justify-content: flex-end;
      padding-right: 35px;

      & > button > a {
        padding: $ks-spacer-3;
      }

      @include respond-below($shared-breakpoint) {
        grid-area: 1 / 3 / -1 / -1;
      }

      @include respond-below(sm) {
        grid-area: 1 / 2 / -1 / -1;
        padding-right: 25px;
      }
    }
  }
}
