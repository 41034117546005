@use '../abstracts' as *;

.ks-auto-complete {
  cursor: pointer;
  margin: 0;
  max-width: 326px;

  .mdc-text-field {
    align-items: center;
    background-color: $color-lighter-grey !important;
    border-radius: 0 !important;
    height: 40px;
    margin: 0;

    &__clear {
      place-items: center !important;
    }

    &__post-fixed-icon {
      background-color: $color-white;
      border-left: 1px solid rgba(204 204 204) !important;
    }

    &__input {
      border-bottom: 1px solid $color-medium-grey;
      color: $color-brand-primary !important;
      padding-top: 7px;
      text-transform: none;
      transition: border-width 200ms ease-in, border-color 200ms ease-in;


      &::placeholder {
        color: $color-brand-primary !important;
        font-size: $ks-font-size-3;
        opacity: 1;
      }
    }
  }
}

.lsg .mdc-text-field {

  .mdc-notched-outline {
    border: 1px solid rgba(204 204 204) !important;
  }

  .mdc-notched-outline__leading {
    border: none !important;
  }

  .mdc-notched-outline__trailing {
    border: none !important;
  }
}
