@use 'colors' as *;
@use 'variables' as *;

$font-weight-bold: 700;
$font-weight-black: 800;

h1, h2, h3, h4, h5, h6 {
  font-family: var(--mdc-typography-font-family);
}

.lsg h1 {
  color: $color-anthrazit;
  font-size: $ks-font-size-7;
  line-height: 35px;
  font-weight: $font-weight-bold;
  padding: 20px 0;
  text-transform: capitalize;
  margin: 0;
}
