@use '../abstracts' as *;

.ks-tabs {
  margin-block: $ks-spacer-4;

  &--full-width {
    display: flex;
    flex-direction: column;

    .ks-tab {
      &-link {
        flex: 1;
      }

      &-links {
        display: flex;
      }
    }
  }
}

.ks-tab {
  &-links button {
    background: rgba(23, 143, 206, 0.05);
    border: none;
    border-bottom: 2px solid transparent;
    color: $color-bluish;
    cursor: pointer;
    padding: $ks-spacer-2 $ks-spacer-3;

    &:hover {
      background-color: rgba(23, 143, 206, 0.05);
      border-bottom-color: $color-bluish;
    }

    &.active {
      background: rgba(23, 143, 206, 0.19);
      border-bottom-color: $color-bluish;
    }
  }

  &-content {
    margin-block: $ks-spacer-3;
    width: 100%;
  }

  &-pane {
    display: none;
  }

  &-pane.active {
    display: block;
  }
}

