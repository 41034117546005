@use '../../../abstracts' as *;

.ks-order-details-information {
  display: flex;
  flex-wrap: wrap;
  gap: $ks-spacer-3;
  margin-bottom: $ks-spacer-4;
  padding: 0 $ks-spacer-3;

  &-block {
    display: flex;
    flex: 3 1 250px;
    flex-direction: column;
    gap: $ks-spacer-2;
    padding: $ks-spacer-1;

    @include respond-below(sm) {
      flex-basis: 100%;
    }

    &__title {
      font-weight: $font-weight-bold;
      text-transform: uppercase;
    }

    &__content {
      column-gap: $ks-spacer-2;
      display: flex;
      flex-direction: column;
    }

    &--small {
      flex: 1 1 210px;
    }

    &--delivery-plant.ks-order-details-information-block--means-of-transport {
      @include respond-below(md) {
        column-gap: $ks-spacer-8;
        flex: 1 1 450px;
        flex-direction: row;
      }

      @include respond-below(sm) {
        flex-direction: column;
        row-gap: $ks-spacer-4;
      }
    }
  }
}
