@use '../abstracts' as *;

.ks-spinner {
  animation: rotation 1s linear infinite;
  border: 7px solid $color-brand-primary;
  border-bottom-color: transparent;
  border-radius: 50%;
  box-sizing: border-box;
  display: inline-block;

  &__text {
    animation: fadeInOut 20s forwards; /* Total duration is 20s */
    color: $color-brand-primary;
    display: flex;
    font-size: $ks-font-size-5;
    height: 100%; /* Ensures it takes the full height of the container */
    opacity: 0;
    place-content: center;
    position: absolute;
    width: 100%; /* Ensures it takes the full width of the container */

    @keyframes fadeInOut {
      0%, 100% {
        opacity: 0;
      }
      5%, 15% {
        opacity: 1;
      }
      18%, 22% {
        opacity: 0;
      }
      /* General fade in/out behavior */
    }

    @keyframes fadeInAndStay {
      0%, 15% {
        opacity: 0;
      }
      15%, 100% {
        opacity: 1;
      }
    }

    &:nth-child(1) {
      animation-delay: 0s;
    }

    &:nth-child(2) {
      animation-delay: 4s;
    }

    &:nth-child(3) {
      animation-delay: 8s;
    }

    &:nth-child(4) {
      animation-delay: 12s;
    }

    &:nth-child(5) {
      animation: fadeInAndStay linear 4s 16s 1 normal forwards;
    }

    &-wrapper {
      display: grid;
      height: max(0px, min-content);
      margin-block: $ks-spacer-5;
      place-items: center;
      position: relative;
      width: clamp(70%, 80%, 90%);
    }
  }

  &__wrapper {
    display: grid;
    place-items: center;

    &--absolute {
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%) translateX(-50%);
    }
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}











