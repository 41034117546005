@use '../../abstracts' as *;

.ks-checkout {
  &-cards {
    display: flex;
    flex-wrap: wrap;
    gap: $ks-spacer-3;
    margin-block: $ks-spacer-7 $ks-spacer-2;

    .ks-card {
      flex: 1 1 270px;

      &--contact-person {
        flex: 2 1 418px;
        padding-block: $ks-spacer-2;

        @include respond-between(sm, md) {
          flex-grow: 0;
        }
      }
    }
  }

  &-navigation {
    display: flex;
    flex-direction: column;
    width: min(613px, 100%);

    &__wrapper {
      align-items: end;
      display: flex;
      justify-content: space-between;
      margin-block: $ks-spacer-8;
    }

    &__place-order {
      &--readonly {
        @include readonly()
      }
    }
  }

  &-terms-and-conditions {
    background-color: $color-lightest-grey;

    &__header {
      background-color: $color-grey;
      color: $color-white;
      font-size: calc($ks-spacer-3 + $ks-spacer-1);
      font-weight: $font-weight-bold;
      padding: $ks-spacer-2 $ks-spacer-4;
    }

    &__actions {
      column-gap: calc($ks-spacer-2 + $ks-spacer-1);
      display: flex;
      flex-direction: row-reverse;
      min-height: 130px;
      padding: $ks-spacer-3;

      label {
        font-size: $ks-font-size-4;
        font-weight: normal;
      }

      input[type="checkbox"] {
        $size: 22px;
        align-self: flex-start;
        margin-block-start: $ks-spacer-1;
        min-height: $size;
        min-width: $size;
      }
    }
  }
}

.modal#billToSelectionModal {
  .ks-card {
    transition: all 150ms ease;

    &--selected {
      border: 1px solid $color-brand-primary;
      @include disable-interactions
    }

    &--in-modal {
      &:hover {
        border-color: $color-hover;
        @include shadow()
      }
    }
  }
}
