@use '../abstracts' as *;

.ks-divider {
  background-color: $color-medium-grey;
  border: 0;
  height: 1px;
  margin: $ks-spacer-3 0;
  padding: 0;
}

.ks-divider--0 {
  @extend .ks-divider;
  margin: $ks-spacer-0;
}

.ks-divider--32 {
  @extend .ks-divider;
  margin: $ks-spacer-5 0;
}

.ks-divider--48 {
  @extend .ks-divider;
  margin: $ks-spacer-6 0;
}
