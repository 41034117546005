@use '../../abstracts' as *;

.ks-product-selection {
  &-form {
    position: relative;
    z-index: 1;
  }

  &-list {
    container-type: inline-size;

    &__continue-shopping {
      display: flex;
      justify-content: flex-end;
    }

    &-item {
      display: grid;
      grid-gap: $ks-spacer-4;
      grid-row-gap: 0;
      grid-template-columns: 96px 2.48fr 1.2fr 1.6fr 32px;
      grid-template-rows: repeat(4, auto);
      margin-block: $ks-spacer-5;
      padding: $ks-spacer-1;

      &__image {
        display: flex;
        grid-area: 1 / 1 / -1 / span 1;
      }

      &__description {
        grid-area: 1 / 2 / 2 / span 1;
      }

      &__code {
        grid-area: 2 / 2 / 3 / span 1;
      }

      &__chips {
        grid-area: 3 / 2 / 4 / span 1;
        margin-block-start: 1.35rem;
      }

      &__name {
        font-weight: $font-weight-bold;
      }

      &__pickup-location {
        display: none;
        grid-area: 1 / 3 / -1 / span 1;
      }

      &__price {
        &--per-unit {
          grid-area: 1 / 3 / -1 / span 1;
          place-self: flex-start center;
        }
      }

      &__quantity {
        align-self: center;
        display: inline-flex;
        flex-direction: column;
        grid-area: 1 / 4 / -1 / span 1;
        place-self: flex-start;
      }

      &__delete-button {
        display: flex;
        font-size: $ks-font-size-5;
        grid-area: 1 / 5 / -1 / span 1;
        place-items: flex-start;

        button {
          @include button-icon();
        }
      }

      &__update-button {
        grid-area: 1 / 5 / -1 / span 1;
        place-self: flex-start center;

        .button.button--primary {
          margin: 0;
        }
      }

      &__empty-pickup-location-error {
        display: flex;
        flex-direction: column;
        font-size: $ks-font-size-3;

        span:nth-child(1) {
          color: $color-dark-grey
        }

        span:nth-child(2) {
          color: $color-subsegment-agriculture
        }
      }
    }
  }
}

/*WITH PICKUP LOCATION*/
.ks-product-selection-list-item:has(.ks-product-selection-list-item__pickup-location) {
  grid-template-columns: 96px 1.37fr 1fr 1.2fr 1.6fr 32px;

  .ks-product-selection-list-item__chips {
    grid-column-end: span 2;
  }

  .ks-product-selection-list-item__pickup-location {
    display: block;
    grid-column-start: 3;

    &--readonly {
      display: flex;
      flex-direction: column;
      gap: $ks-spacer-1;
      margin-block-start: initial;
    }

    &-label {
      color: $color-grey;
      font-size: $ks-font-size-1;
      text-transform: uppercase;
    }

    &-wrapper {
      align-items: center;
      column-gap: $ks-spacer-2;
      display: flex;

      &--skeleton:has(> span:empty) {
        @include skeleton($width: 12ch, $height: 23px);
      }
    }
  }

  .ks-product-selection-list-item__price--per-unit {
    grid-column-start: 4;
  }

  .ks-product-selection-list-item__quantity {
    grid-column-start: 5;
  }

  .ks-product-selection-list-item__delete-button {
    grid-column-start: 6;
  }
}


@container (max-inline-size: 1005px) {
  .ks-product-selection-list-item, .ks-product-selection-list-item:has(.ks-product-selection-list-item__pickup-location) {
    grid-gap: $ks-spacer-2;
    grid: auto-flow / 96px 3fr 1fr 32px;

    .ks-product-selection-list-item__image {
      grid-area: 1 / 1 / span 3 / 2;
    }

    .ks-product-selection-list-item__description {
      grid-area: 1 / 2 / 2 / 3;
    }

    .ks-product-selection-list-item__code {
      grid-area: 2 / 2 / span 1 / span 1;
    }

    .ks-product-selection-list-item__chips {
      grid-area: 3 / 2 / span 1 / span 2;
      margin: initial;
    }

    .ks-product-selection-list-item__pickup-location {
      grid-area: 5 / 2 / span 1 / span 1;
    }

    .ks-product-selection-list-item__price--per-unit {
      grid-area: 4 / 2 / span 1 / span 1;
      place-self: center flex-start;
    }

    .ks-product-selection-list-item__quantity {
      grid-area: 1 / 3 / span 3 / 4;
      place-self: flex-start;
    }

    .ks-product-selection-list-item__delete-button {
      grid-area: 1 / 4 / -1 / 5;
      place-self: flex-start flex-end;
    }
  }
}

@container (max-inline-size: 650px) {
  .ks-product-selection-list-item, .ks-product-selection-list-item:has(.ks-product-selection-list-item__pickup-location) {
    grid-column-gap: $ks-spacer-3;
    grid: repeat(5, min-content) / 96px 1fr 32px;
    grid-row-gap: 4px;

    .ks-product-selection-list-item__image {
      display: flex;
      grid-area: 1 / 1 / 4 / 2;
    }

    .ks-product-selection-list-item__description {
      grid-area: 1 / 2 / 2 / 3;
    }

    .ks-product-selection-list-item__code {
      grid-area: 2 / 2 / 3 / 3;
    }

    .ks-product-selection-list-item__chips {
      grid-area: 3 / 2 / 4 / 5;
      margin-bottom: $ks-spacer-5;
    }

    .ks-product-selection-list-item__price--per-unit {
      grid-area: 4 / 2 / 5 / 3;
      place-self: center flex-start;
    }

    .ks-product-selection-list-item__quantity {
      grid-area: 5 / 2 / 6 / 5;
      place-self: flex-start;
    }

    .ks-product-selection-list-item__delete-button {
      grid-area: 1 / 3 / 7 / 4;
      place-self: flex-start flex-end;
    }

    .ks-product-selection-list-item__pickup-location {
      grid-area: 6 / 2 / span 1 / span 1;
    }
  }
}

.loading-because-transportTypesForm, .loading-because-deliveryTablesForm {
  .ks-product-selection-list {
    @include loadingState();
  }
}
