@use '../../../../abstracts' as *;

.ks-order-details-card {
  @include respond-below(md) {
    margin: $ks-spacer-3 0;
  }

  &__header {
    background-color: $color-brand-primary;
    border-bottom: 1px solid $color-brand-tertiary;
    color: $color-white;
    font-weight: $font-weight-bold;
    padding: $ks-spacer-3 $ks-spacer-5;
  }

  &__content {
    border: 1px solid $color-brand-tertiary;
    display: block;
    min-height: calc(4 * $ks-spacer-5);
    padding: $ks-spacer-3 $ks-spacer-5;

    p {
      margin: 0;
    }

    &--not-provided {
      font-style: italic;
    }
  }
}
