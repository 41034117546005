@use '../abstracts' as *;

.ks-date-input {
  .mdc-text-field {
    align-items: center;
    border: 1px solid $color-border;
    border-radius: 0;
    display: flex;
    position: relative;

    label#ks-date-picker-label {
      @include prefixed-label($left: 10px)
    }

    .icon-calendar {
      color: $color-brand-primary;
      font-size: $ks-font-size-5;
      position: absolute;
      right: $ks-spacer-3;
      z-index: 2;
    }

    &:not(.mdc-text-field--error):hover,
    &:not(.mdc-text-field--error) &:has(input:focus, input:active, input:hover) {
      border-color: $color-bluish;

      .icon-calendar {
        color: $color-bluish;
      }

      label#ks-date-picker-label {
        color: $color-bluish;
      }
    }

    &::after {
      aspect-ratio: 1/ 1;
      background: $color-white;
      content: '';
      position: absolute;
      right: .7rem;
      width: 30px;
      z-index: 1;
    }

    &--error {
      border-color: red;

      .icon-calendar, .mdc-text-field__input {
        color: red;
      }
    }

    &--grey {
      &::after {
        aspect-ratio: 1/ 1;
        background: $color-lighter-grey;
        content: '';
        position: absolute;
        right: .7rem;
        width: 30px;
        z-index: 1;
      }
    }
  }
}
