@use '../abstracts' as *;

.lsg {
  .mdc-text-field {
    &__icon {
      height: 27px;
      width: 27px;

      &::before {
        font-size: 27px;
      }

      ~ .mdc-text-field__input {
        padding-left: 36px;
      }
    }

    &__clear.show-clear-input {
      display: block;
    }

    &__clear {
      margin-inline-start: -$ks-spacer-4 !important;
      background-color: #ccc;
      border-radius: 50%;
      color: #fff;
      cursor: pointer;
      display: none;
      font: 16px monospace;
      height: 20px;
      line-height: 1em;
      position: absolute;
      right: 10px;
      text-align: center;
      width: 20px;
    }

    &__post-fixed-icon {
      aspect-ratio: 1 / 1;
      display: grid;
      font-size: $ks-font-size-6;
      padding-inline: 8px;
      place-items: center;

      &--hidden {
        display: none;
      }
    }
  }

}
