@use '../../abstracts' as *;

.ks-bubble-stepper-nav {
  display: grid;
  margin-block-end: $ks-spacer-3;
  place-items: center;

  &__title {
    font-size: $ks-font-size-7;
    font-weight: $font-weight-bold;
    margin: 0;
    margin-block-end: $ks-spacer-4;
    padding: 20px 30px;
  }

  &-steps {
    counter-reset: step;
    display: flex;
    flex-flow: row nowrap;
    width: min(1000px, 100%);

    &__step-item {

      align-items: center;

      color: $color-anthrazit;
      $step-item: &;
      display: flex;
      flex: 1 1 0;
      flex-direction: column;
      list-style: none;
      position: relative;
      text-align: center;

      &:not(&--active, &--completed) {
        @include disable-interactions;
      }

      &::before {
        background-color: $color-lighter-grey;
        border: 2px solid $color-lighter-grey;
        border-radius: 50%;
        content: counter(step);
        counter-increment: step;
        display: block;
        height: 30px;
        line-height: 26px;
        margin: 0 auto 10px;
        text-align: center;
        width: 30px;
      }

      &::after {
        background-color: $color-light-grey;
        content: "";
        height: 2px;
        left: calc(50% + 32px);
        position: absolute;
        top: 15px;
        width: calc(100% - 65px);
        z-index: -1;
      }

      &:last-child {
        cursor: auto;

        &:after {
          content: none
        }
      }

      &--active, &--active:hover {
        color: $color-brand-secondary;

        &::before {
          background-color: transparent;
          border: 2px solid $color-brand-secondary;
        }
      }

      &--completed, &--completed:hover {
        color: $color-brand-secondary;

        &::before {
          background-color: $color-brand-secondary;
          border-color: $color-brand-secondary;
          color: $color-white;
          content: "✔";
        }
      }
    }
  }
}
