@use '../../../abstracts' as *;

//Resetting the lsg styles for list items
ul.ks-delivery-transport-card__info-list > li.ks-delivery-transport-card__info-list-item {
  line-height: 2em;
  margin-bottom: initial;
  padding-left: initial;

  &::before {
    content: '';
  }
}

.ks-delivery-transport-card {
  display: flex;
  flex: 1 1 318px;
  flex-direction: column;
  max-width: 400px;
  outline: 2px solid $color-border;
  padding: $ks-spacer-3 $ks-spacer-4 $ks-spacer-4 $ks-spacer-4;
  row-gap: $ks-spacer-3;
  transition: all 150ms ease;

  @include respond-below(md) {
    max-width: initial;
  }

  &:not(.ks-delivery-transport-card--active) {
    cursor: pointer;

    &:hover {
      @include shadow-semi-smooth;
      outline: 3px solid $color-hover;

      .ks-single-breadcrumb {
        color: $color-hover
      }
    }
  }

  &--active {
    outline: 3px solid $color-subsegment-feed;
    @include shadow-smooth;
  }

  &__title {
    font-size: $ks-font-size-5;

    &-wrapper {
      display: flex;
      flex-direction: column;
      row-gap: $ks-spacer-2;
    }
  }

  &__header {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  &__info-list {
    display: flex;
    flex-direction: column;

    &-item {
      display: flex;
      justify-content: space-between;
    }
  }

  &__divider {
    margin: -$ks-spacer-2 0;
  }

  &__total-value {
    font-size: $ks-font-size-5;
    font-weight: $font-weight-bold;
  }

  &__footer {
    align-items: center;
    display: flex;
    justify-content: flex-end;
  }

  &--placeholder {
    align-items: center;
    justify-content: center;
    max-width: 326px;
    min-height: 280px;
    outline: 3px dashed $color-border;

    &:is(:first-child, &:not(:first-child)):hover {
      box-shadow: 0 0 0;
      cursor: initial;
      outline: 3px dashed $color-border;
      user-select: none;
    }
  }
}
