@use '../../abstracts' as *;

.ks-customer-internal-service-contact {
  display: flex;
  flex-direction: column;
  margin: $ks-spacer-4 0;
  padding: $ks-spacer-5;
  place-items: center;

  &-container {
    container-name: serviceContact;
    container-type: inline-size;
  }

  .ks-contact-hero {
    margin: $ks-spacer-3 0;
    padding: $ks-spacer-3;
    text-align: center;
    width: clamp(250px, 100cqi, 700px);

    &__title {
      font-size: $ks-font-size-6;
      font-weight: bold;
    }

    &__sub-title {
      color: $color-grey;
      display: block;
    }
  }

  .ks-contact {
    display: grid;
    grid-column-gap: $ks-spacer-5;
    grid-row-gap: $ks-spacer-2;
    grid-template-columns: auto 1fr;
    grid-template-rows: repeat(3, auto);

    &__img-wrapper {
      align-items: center;
      display: flex;
      flex-direction: column;
      grid-row: 1 / 3;

      .fa-icon {
        background-color: $color-brand-primary;
        color: white;
        font-size: 50px;
        font-weight: $font-weight-bold;
      }


      a {
        border-radius: $ks-spacer-4;
        color: $color-white;
        margin-top: -$ks-spacer-4;
        padding: $ks-spacer-2 $ks-spacer-3;
      }
    }

    &__image {
      aspect-ratio: 1/1;
      border-radius: 50%;
      display: grid;
      object-fit: cover;
      place-items: center;
      width: clamp(170px, 100cqi, 170px);
    }

    &__information {
      display: inline-flex;
      flex-direction: column;
      grid-area: 1 / 2 / 2 / 3;
      justify-content: flex-end;
      row-gap: $ks-spacer-1;
    }

    &__name {
      font-size: $ks-spacer-4;
      font-weight: $font-weight-bold;
    }

    &__position {
      color: $color-grey;
    }

    &__actions {
      grid-area: 2 / 2 / 3 / 3;

      &-phone-wrapper {
        color: $color-bluish;
        column-gap: calc($ks-spacer-2 + $ks-spacer-1);
        display: flex;

        .f-icon {
          font-size: 1.1em;
        }
      }
    }
  }

  @container serviceContact (max-width: 600px) {
    .ks-customer-internal-service-contact {
      padding: $ks-spacer-3;
    }

    .ks-contact {
      grid-row-gap: $ks-spacer-1;
      grid-template-columns: 1fr;
      place-items: start center;

      &__img-wrapper, &__actions, &__information {
        grid-area: initial;
      }

      &__actions, &__information {
        place-items: center;
      }

      &__img-wrapper {
        margin-bottom: $ks-spacer-3;

        .fa-icon {
          font-size: 70px;
        }
      }

      &__image {
        width: clamp(200px, 100cqi, 200px);
      }
    }
  }
}
