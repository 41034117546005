@use '../../abstracts' as *;

.ks-card .ks-contact {
  display: grid;
  grid: auto-flow / 2fr 1fr;
  grid-column-gap: $ks-spacer-4;
  grid-row-gap: $ks-spacer-2;

  &__img-wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    grid-area: 1 / 2 / 2 / 3;

    .fa-icon {
      background-color: $color-brand-primary;
      color: white;
      font-size: 38px;
      font-weight: $font-weight-bold;
    }

    a {
      border-radius: $ks-spacer-4;
      color: $color-white;
      margin-top: -$ks-spacer-4 !important;
      padding: $ks-spacer-2 $ks-spacer-3;
    }
  }

  &__image {
    aspect-ratio: 1/1;
    border-radius: 50%;
    display: grid;
    object-fit: cover;
    place-items: center;
    width: min(130px, 100%);
  }

  &__information {
    display: inline-flex;
    flex-direction: column;
    grid-area: 1 / 1 / 2 / 2;
    justify-content: center;
    row-gap: $ks-spacer-1;
  }

  &__name {
    font-weight: $font-weight-bold;
  }

  &__position {
    color: $color-grey;
  }

  &__actions {

    &-email-wrapper,
    &-phone-wrapper {
      color: $color-bluish;
      column-gap: calc($ks-spacer-2 + $ks-spacer-1);
      display: flex;

      .f-icon {
        font-size: 1.1em;
      }
    }
  }
}

