@use '../../abstracts' as *;
@use 'sass:color';

.ks-ship-to-card {
  border: 1px solid $color-border;
  display: grid;
  grid: auto-flow / 1fr;
  margin-bottom: $ks-spacer-3;
  position: relative;
  transition: all 150ms ease;

  @media print {
    page-break-inside: avoid;
  }

  &--edit {
    cursor: pointer;
    padding: $ks-spacer-2;

    &:hover {
      border-color: $color-hover;
      @include shadow()
    }
  }

  &--selected {
    border: 1px solid $color-brand-primary;
    @include disable-interactions
  }

  &--with-discounts {
    grid: auto-flow / 1fr 1fr;
  }

  &__wrapper {
    display: grid;
    grid-auto-rows: 1fr;
    grid-column-gap: $ks-spacer-2;
    grid-template-columns: 32px 1fr 32px;
    padding: $ks-spacer-4 $ks-spacer-2;
  }

  &__label {
    background-color: $color-white;
    color: $color-grey;
    font-size: $ks-font-size-3;
    left: 18px;
    padding: $ks-spacer-1 $ks-spacer-2;
    position: absolute;
    top: -13px;

    @media print {
      display: none;
    }
  }

  &__icon {
    color: $color-brand-primary;
    grid-area: 1 / 1 / -1 / 2;
    place-self: flex-start center;
  }

  &__information {
    display: flex;
    flex-direction: column;
    grid-area: 1 / 2 / -1 / 3;
    margin-right: $ks-spacer-5;
    row-gap: $ks-spacer-1;
  }

  &__delivery-address {
    display: flex;
    flex-direction: column;
  }

  &__actions {
    color: $color-brand-primary;
    font-weight: $font-weight-bold;
    grid-area: 1 / 3 / -1 / 4;
    place-self: flex-start center;

    button {
      @include button-icon()
    }
  }

  &__discount {
    &s {
      background-color: $color-light-grey;
      display: grid;
      grid: auto-flow min-content / 2fr 1fr;
      grid-column-gap: $ks-spacer-3;
      grid-row-gap: $ks-spacer-2;
      padding: $ks-spacer-4;
    }

    &-value {
      place-self: flex-end;
    }
  }
}
