@use '../../../abstracts' as *;

.account-contingent-check {
  &__filter {
    .form-control {
      margin-right: 25px;
      width: 300px;
    }

    .searchButton {
      padding-left: 12px;
    }
  }

  &__error-message-no-data,
  &__error-message-invalid-input {
    display: none;
  }

  &__overview-button {
    margin-bottom: 15px;
    padding-left: 10px;
  }

  &__overview-table {
    h4 {
      color: $color-brand-tertiary;
      margin-bottom: 12px;
      margin-top: 24px !important;
    }

  }

  &__overview-list-headline-month {
    text-transform: unset;
  }

  &__overview-list-headline-year {
    color: $color-brand-tertiary !important;
  }

  &__overview-list-entry-value {
    cursor: pointer;
  }
}

.contingent-check__wrapper {
  display: none;
  height: 80vh;
  margin: auto;
  position: relative;
  width: 80vw;

  @media (max-width: 639px) {
    width: 100vw;
  }
  @media (min-width: 1800px) {
    width: 50vw;
  }
}

#contingent-check {
  margin-top: 75px;
}

