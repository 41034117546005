@use '../../abstracts' as *;

.ks-delivery-configuration__container {
  container-name: config;
  container-type: inline-size;
  margin-block: $ks-spacer-4;
}

.ks-delivery-configuration {
  &__notes {
    display: flex;
    flex-direction: column;
    margin: $ks-spacer-3 0;
  }

  &__preferred-delivery-date.loading-because-transportTypesForm,
  &__preferred-delivery-date.loading-because-deliveryTablesForm {
    @include loadingState();
  }

  &-blocks {
    display: grid;
    gap: $ks-spacer-5;
    grid-template-columns: minmax(350px, 30fr) minmax(300px, 15fr) minmax(350px, 20fr);
    margin-top: $ks-spacer-5;


    &__delivery-mode {
      @include skeleton($height: 80px);
      margin-bottom: $ks-spacer-3;
    }

    &__plant {
      @include skeleton($height: 40px)
    }
  }
}

@media only screen and (max-width: 1450px) {
  .ks-delivery-configuration-blocks {
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  }
}

div.modal#shipToSelectionModal {
  .modal-dialog {
    overflow-y: initial;
  }

  .modal-body {
    max-height: calc(100svh - 300px);
    overflow-y: auto;
  }
}
