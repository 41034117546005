@use '../abstracts' as *;

.ks-single-breadcrumb {
  box-sizing: border-box;
  color: $color-grey;
  display: inline-block;
  font-size: 1rem;
  font-weight: 400;
  margin-top: $ks-spacer-3;
  scroll-padding-top: 11.75rem;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-size-adjust: 100%;

  &:before {
    color: $color-bluish;
    content: "/";
    font-weight: 600;
    margin-right: 0.25rem;
  }

  &--0 {
    margin: 0
  }
}
