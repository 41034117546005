@use '../../../abstracts' as *;

.account-order-history-search-form {
  .simple-search-section {
    display: flex;
    flex-flow: row wrap;
    margin-inline-end: $ks-spacer-5;
    width: calc(100% + 32px);
    column-gap: $ks-spacer-3;
  }

  &.account-order-history--loading + .account-order-history {
    pointer-events: none;
    position: relative;
    user-select: none;

    &::after {
      background-color: rgba(255, 255, 255, 0.5);
      bottom: 0;
      content: '';
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }

    .account-order-history-search-spinner {
      display: block;
      height: 64px;
      left: 50%;
      position: absolute;
      top: 30%;
      transform: translate(-50%, -50%);
      width: 64px;
    }
  }

  .account-order-history-search-field,
  .account-order-history-advanced-search {
    flex: 1 1 auto;
    margin: 16px 32px 0 0;
    min-width: 260px;

    .mdc-text-field {
      align-items: center;
      background-color: transparent;
      height: 40px;
      margin: 0;
    }

    .mdc-text-field__input {
      border-bottom: 1px solid $color-medium-grey;
      padding-top: 7px;
      transition: border-width 200ms ease-in, border-color 200ms ease-in;

      &:hover {
        border-bottom: 1px solid $color-dark-grey;
      }

      &:focus {
        border-bottom: 2px solid $color-brand-primary;
      }
    }

    .popover-content {
      padding: 0 !important;

      .mdc-list {
        padding-left: 0;
      }
    }
  }

  .account-order-history-search-field {
    .mdc-notched-outline {
      display: none;
    }
  }

  .advanced-search-input {
    .mdc-notched-outline__leading,
    .mdc-notched-outline__trailing {
      border-width: 1px !important;
    }

    .mdc-text-field__clear {
      margin-inline-start: -28px;
    }
  }

  .account-order-history-search-button,
  .account-order-history-extended-search-button,
  .account-order-history-close-extended-search-button {
    flex: 0 0 auto;
    height: 3rem;
    margin: 16px 32px 0 auto;
    padding-left: 32px;
    padding-right: 32px;
  }

  .account-order-history {
    .account-order-history-search-spinner {
      display: none;
    }
  }
}

.account-order-history-advanced-search-label {
  padding: 5px;
  width: 200px;
}

.account-order-history-advanced-search-wrapper {
  padding: 5px 0 0;
}

.account-order-history-advanced-search__date {
  display: flex;
}

.advanced-search-input {
  margin: 0 !important;
  width: 321px;

  &.date-input {
    width: auto;
  }
}

.date-input:first-child {
  padding-right: 20px;
}

.account-order-history-close-extended-search-button,
.advanced-search-section {
  display: none !important;
}

.showAdvancedSearch {
  .account-order-history-extended-search-button {
    display: none;
  }

  .account-order-history-close-extended-search-button {
    display: inherit !important;
  }

  .advanced-search-section {
    display: block !important;
  }
}

.mdc-list-item {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 48px;
  letter-spacing: 0.15px;
  padding-right: 16px;

  &:hover {
    &::before {
      background-color: $color-black;
      opacity: 0.4;
    }
  }
}

.mdc-menu-surface--open {
  display: list-item !important;
  max-height: 300px !important;
}

.hide-anchor {
  display: none !important;
}


@media (max-width: 480px) {
  .account-order-history-advanced-search__date {
    display: block;
  }
  .date-input {
    padding: 0 0 5px 0 !important;
  }
}
