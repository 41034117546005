@use '../../../abstracts' as *;

.order-detail-overview {
  &__actions {
    display: flex;
    justify-content: flex-end;

  }

  &__price-request,
  &__download-document {
    align-items: center;
    background-color: $color-brand-secondary;
    display: flex;
    flex: 0 0 50px;
    font-size: 20px;
    height: 50px;
    justify-content: center;
    margin-right: 16px;

    a {
      color: $color-white;
    }
  }
}
