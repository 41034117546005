$ks-font-size-0: 8px;
$ks-font-size-1: 10px;
$ks-font-size-2: 12px;
$ks-font-size-3: 14px;
$ks-font-size-4: 16px;
$ks-font-size-5: 18px;
$ks-font-size-6: 22px;
$ks-font-size-7: 28px;
$ks-font-size-8: 36px;
$ks-font-size-9: 48px;
$ks-spacer-0: 0;
$ks-spacer-1: 4px;
$ks-spacer-2: 8px;
$ks-spacer-3: 16px;
$ks-spacer-4: 24px;
$ks-spacer-5: 32px;
$ks-spacer-6: 48px;
$ks-spacer-7: 64px;
$ks-spacer-8: 96px;
