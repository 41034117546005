@use '../../abstracts' as *;

.ks-delivery-offer {

  &__notes {
    display: flex;
    flex-direction: column;
    margin: $ks-spacer-3 0;
  }

  &__skeleton {
    &--product-selection {
      @include skeleton($width: 100%, $height: 100px, $mb: $ks-spacer-2, $mt: $ks-spacer-2);
    }
  }

  &__navigation {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;

    @include respond-below(sm) {
      flex-direction: column;
      gap: $ks-spacer-3;
    }

    .button {
      @include respond-below(sm) {
        width: 100%;
      }
    }
  }

  &-buttons {
    display: flex;
    justify-content: space-between;
  }
}

.ks-delivery-tables-form {
  &__spinner {
    display: none;
  }

  &--loading {
    .ks-delivery-tables-form__spinner {
      @include skeleton($height: 450px, $mt: $ks-spacer-3, $mb: $ks-spacer-5);
      grid-template-columns: 0.6fr;
    }

    #ks-delivery-offer-fragment {
      display: none;
    }

    a {
      @include disable-interactions()
    }

    .button {
      @include readonly()
    }
  }
}
