label:not([class]) {
  text-transform: inherit !important;
}

.icon {
  font-weight: inherit !important;
}

a.link--no-underline {
  &:hover {
    text-decoration: none;
  }
}
