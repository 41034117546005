.lsg {
  .table {
    th,
    td {
      background-color: unset !important;
    }

    tr,
    td,
    th {
      height: 18px;
    }

    td {
      padding-right: 10px;
      padding-left: 10px;
    }
  }
}
