@use '../../../abstracts' as *;

.order-entry-item-detail-list {
  background-color: $color-white;
  border: 2px solid #e5e5e5;
  box-shadow: rgba(0, 0, 0, 0.05) 0 6px 24px 0, rgba(0, 0, 0, 0.08) 0 0 0 1px;
  display: none;
  font-weight: bold;
  margin: 0px -30px;
}

.order-entry-item-detail:not(:only-child) {
  &:first-child {
    padding-top: 60px;
  }
}

.item-list .order-entry-item-detail {
  background-color: $color-white !important;
  height: 75px;
  padding-top: 15px;
  padding-left: 30px;
  padding-right: 30px;

  &:not(:last-child) {
    border-bottom: 3px solid #e5e5e5;
  }

  div {
    display: table-cell;
    padding: 0 10px;
    vertical-align: middle;

    &:last-child {
      text-align: end;
    }
  }
}

.order-entry-item-detail__image,
.order-entry-item-detail__code {
  width: calc(100% / 6);
}

.order-entry-item-detail__entryNumber,
.order-entry-item-detail__quantity {
  width: calc(100% / 6 / 2);
}

.order-entry-item-detail__name,
.order-entry-item-detail__status {
  width: calc(100% / 6 * 2);
}

.order-entry-item-detail.action {
  text-align: right;

  form {
    padding: unset;
  }
}

@media (max-width: 800px) {
  .order-entry-item-detail-list {
    margin-top: 20px !important;
  }
  .order-entry-item-detail:first-child {
    padding-top: 20px !important;
  }
  .order-entry-item-detail.action {
    padding-top: 0;
  }
  .order-entry-item-detail {
    padding-top: 15px;

    div {
      display: block !important;
      margin-left: 0 !important;
      width: 100%;

      &:last-child {
        text-align: unset !important;
      }
    }
  }
}

@media (max-width: 1025px) {
  .order-entry-item-detail {
    height: unset !important;
  }
  .order-entry-item__more-button {
    margin-bottom: 8px !important;
    margin-top: 8px !important;
  }
}
