@use '../../../abstracts' as *;
@use 'sass:color';

.order-entry-item {
  border-top: 2px solid $color-brand-quaternary;
  padding-left: 30px;
  padding-right: 30px;

  &:nth-child(even) {
    background-color: #f2f2f2;
  }

  &:last-child {
    border-bottom: 2px solid $color-brand-quaternary;
  }
}

// dont know why last-child doesnt work here as there are only three items
.order-history-teaser-home {
  .order-entry-item:nth-child(3) {
    border-bottom: 2px solid $color-brand-quaternary;
  }
}

.order-entry-item__first-line,
.order-entry-item__second-line {
  display: flex;
  padding-bottom: 8px;
}

.order-entry-item__headline {
  color: color.adjust($color-brand-primary, $lightness: -15%) !important;
  font-weight: bold;

  a {

    &:hover {
      color: $color-brand-primary !important;
    }
  }
}

.order-entry-item__actions {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  column-gap: $ks-spacer-3;

  button {
    height: 40px !important;
    margin-right: 16px;
    min-width: 122px !important;

    .glyphicon-menu-up::before,
    .glyphicon-menu-down::before {
      font-size: 18px;
    }
  }
}

.order-entry-item__more-button-content {
  font-size: 25px;
}

.order-entry-item__label {
  color: $color-subsegment-industry;
  margin-bottom: 0;
  text-transform: uppercase;
}

.order-entry-item__value {
  font-size: large;
  font-weight: bold;
  margin-bottom: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.order-entry-item__sapId,
.order-entry-item__orderDate,
.order-entry-item__contract,
.order-entry-item__status {
  width: 20%;
}

.order-entry-item__shipTo,
.order-entry-item__baseProducts,
.order-entry-item__location,
.order-entry-item__actions {
  width: 30%;
}

.order-entry-item__location {
  .order-entry-item__value {
    margin-top: 20px;
  }
}

.order-entry-item__price-request,
.order-entry-item__download-document {
  align-items: center;
  background-color: $color-brand-tertiary;
  display: flex;
  font-size: 20px;
  justify-content: center;
  min-height: 40px;
  min-width: 40px;

  a {
    color: $color-white;
  }
}

@media (max-width: 800px) {
  .order-entry-item__first-line,
  .order-entry-item__second-line {
    display: block;
  }
  .order-entry-item__sapId,
  .order-entry-item__orderDate,
  .order-entry-item__contract,
  .order-entry-item__status,
  .order-entry-item__shipTo,
  .order-entry-item__baseProducts,
  .order-entry-item__location,
  .order-entry-item__actions {
    width: 100%;
  }
}
