@use '../../abstracts' as *;

.ks-delivery-options {
  &-transport-selection {
    &__header {
      align-items: center;
      display: flex;
      justify-content: space-between;
    }

    &__refresh-configuration {
      margin: 0;
    }
  }

  &__notes {
    display: flex;
    flex-direction: column;
    margin: $ks-spacer-3 0;
  }

  &__selection {
    margin: $ks-spacer-6 0 $ks-spacer-2 0;
  }

  &__shipTo {
    display: flex;
    gap: $ks-spacer-4;
  }

  &__navigation {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;

    @include respond-below(sm) {
      flex-direction: column;
      gap: $ks-spacer-3;
    }

    .button {
      @include respond-below(sm) {
        width: 100%;
      }
    }
  }
}

.ks-transport-types-form {
  &__spinner {
    display: none;
  }

  &--loading {
    .ks-transport-types-form__spinner {
      @include skeleton($height: 450px, $mt: $ks-spacer-3, $mb: $ks-spacer-5);
      grid-template-columns: 0.6fr;
    }

    #ks-delivery-options-fragment {
      display: none;
    }

    a {
      @include disable-interactions()
    }

    .button {
      @include readonly()
    }
  }
}
