.ks-my-price {

  h3 {
    color: rgb(0, 58, 120);
    font-size: 1.25rem;
    font-weight: 700;
    margin: 1rem 0;
  }

  .ks-watermark {
    color: red;
    display: none;
    font-size: 8rem;
    left: 50%;
    line-height: 10rem;
    opacity: 4%;
    position: fixed;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;

    @media print {
      display: block;
    }
  }
}
