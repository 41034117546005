@use 'colors' as *;
@use 'variables' as *;
@use 'typographie' as *;
@use 'sass:color';

@mixin badge($top: -8px, $right: null, $bottom: null, $left: null, $width: 19px,
             $background-color: null, $color: null, $border: null,
             $font-size: $ks-font-size-1,
             $position: null) {

  @if $right != null {
    right: $right
  }

  @if $right == null and $left != null {
    left: $left
  }

  @if $position != null {
    position: $position;
  } @else {
    position: absolute;
  }

  top: $top;
  bottom: $bottom;
  aspect-ratio: 1/1;
  border: $border;
  border-radius: 50%;
  background-color: $background-color;
  color: $color;
  width: $width;
  font-size: $font-size;
  font-weight: $font-weight-black;
  display: inline-grid;
  place-items: center;
}

@mixin button($color: null) {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 !important;
  padding: 0 14px;
  position: relative;
  line-height: 20px;
  font-family: inherit;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.65;
  }

  @if $color != null {
    background-color: $color;
    color: $color-white;

    &:not(:disabled):not(.disabled):focus,
    &:not(:disabled):not(.disabled):hover,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus {
      background-color: color.adjust($color, $lightness: -8%);
      border-color: color.adjust($color, $lightness: -8%);
    }
  }

  @if $color == transparent {
    text-transform: uppercase;
    font-weight: $font-weight-bold;
    background-color: transparent;
    color: $color-brand-primary;

    &:not(:disabled):not(.disabled):focus,
    &:not(:disabled):not(.disabled):hover,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus {
      background-color: $color-hover-background;
    }
  }

  /*To ensure proper contrast for yellowish buttons*/
  @if $color == $color-sun-yellow {
    color: $color-black
  }
}

@mixin button-icon {
  background-color: $color-white;
  border: $color-white;
  color: $color-brand-primary;

  &:not(:disabled):not(.disabled):focus,
  &:not(:disabled):not(.disabled):hover,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active:focus,
  &:not(:disabled):not(.disabled).active:focus {
    background-color: color.adjust($color-brand-primary, $lightness: 70%);
    border-color: color.adjust($color-brand-primary, $lightness: 70%);
  }

  i {
    color: $color-brand-primary;
    font-weight: $font-weight-bold;
  }
}

@mixin button-icon-reverse {
  $size: 50px;

  background-color: $color-brand-primary;
  border: $color-brand-primary;
  font-size: $ks-font-size-5;
  min-width: $size;
  width: $size;
  height: $size;

  a {
    color: $color-white;
  }

  &:not(:disabled):not(.disabled):focus,
  &:not(:disabled):not(.disabled):hover,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active:focus,
  &:not(:disabled):not(.disabled).active:focus {
    background-color: color.adjust($color-brand-primary, $lightness: -8%);
    border-color: color.adjust($color-brand-primary, $lightness: -8%);
  }
}

@mixin disable-visually {
  &, &:focus, &:hover, &:active, &.active, &:active:focus, &.active:focus {
    background: $color-disabled-background !important;
    color: $color-disabled-text !important;
    border-color: $color-disabled-background !important;
  }
}

@mixin disable-interactions {
  pointer-events: none;
  user-select: none;
}

@mixin disable-interactives {
  a {
    @include disable-interactions()
  }

  input, .button {
    @include readonly()
  }

}

@mixin loadingState {
  @include disable-interactives();
  @include disable-interactions();
  opacity: 70%;
  filter: grayscale(.6);
}

@mixin prefixed-label($top: 0, $left: 0, $color: $color-grey, $bg-color: $color-white) {
  background-color: $color-white;
  color: $color;
  font-size: $ks-font-size-2;
  left: $left;
  padding: $ks-spacer-1 $ks-spacer-2;
  position: absolute;
  top: $top;
}

@mixin readonly {
  @include disable-visually();
  @include disable-interactions()
}

@mixin shadow {
  box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
}

@mixin shadow-semi-smooth {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

@mixin shadow-smooth {
  box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.03), 0 9px 46px 8px rgba(0, 0, 0, 0.03), 0 11px 15px -7px rgba(0, 0, 0, 0.03);
}

@mixin skeleton($width: 100%, $height: 200px, $rounding: 4px, $mt: 0px, $mb: 0px) {
  display: grid;
  place-content: center;
  width: $width;
  height: $height;
  border-radius: $rounding;
  margin-top: $mt;
  margin-bottom: $mb;
  animation: skeleton-loading 2s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 80%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}
