@use '../../abstracts' as *;

.ks-delivery-checkout-success {
  &-indicator {
    background-color: #dff0d8; // bootstrap success
    color: #3c763d; // bootstrap success
    display: grid;
    gap: $ks-spacer-3;
    padding-block: $ks-spacer-6;
    place-items: center;

    &__text {
      font-size: $ks-font-size-7;
      font-weight: $font-weight-bold;
    }
  }

  &-order-number {
    &__value {
      font-weight: $font-weight-bold;
    }
  }
}
