@use '../../abstracts' as *;

.ks-checkout-order-day {
  margin-block: $ks-spacer-6;
  border-top: 1px solid $color-grey;

  &:is(&:last-child) {
    .ks-checkout-order-item:last-child {
      border-bottom: 1px solid $color-grey;
    }
  }

  &s {
    container-type: inline-size;
    container-name: orderDaysContainer;
  }

  &__information-header {
    background-color: $color-lighter-grey;
    color: $color-brand-primary;
    display: grid;
    font-weight: $font-weight-bold;
    grid: auto-flow / minmax(100px, max-content) max-content 1fr;
    grid-gap: $ks-spacer-8;
    padding: calc($ks-spacer-1 + $ks-spacer-2) $ks-spacer-5;
    place-items: center flex-start;

    $infoHeader: &;
  }

  &__number-of-deliveries {
    span {
      @include badge($color: $color-brand-primary, $border: 2px solid $color-brand-primary, $position: inital, $font-size: 14px, $width: 24px)
    }
  }

  &__delivery-date {
    color: $color-black;
    font-weight: $font-weight-bold;
    display: flex;
    flex-direction: column;
    line-height: 1em;
    margin-block-start: -$ks-spacer-1;
    gap: $ks-spacer-1;

    & > span:nth-child(1) {
      color: $color-dark-grey;
      font-weight: normal;
      text-transform: uppercase;
    }
  }
}

@container orderDaysContainer (max-inline-size: 540px) {
  .ks-checkout-order-day__information-header {
    display:flex;
    justify-content: space-between;
    grid-gap: initial;
  }
}
