@use '../abstracts' as *;

.button {
  @include button();

  &--primary {
    @include button($color: $color-brand-primary)
  }

  &--primary-light {
    @include button($color: $color-brand-tertiary)
  }

  &--secondary {
    @include button($color: $color-brand-secondary)
  }

  &--success {
    @include button($color: $color-subsegment-feed);
  }

  &--info {
    @include button($color: $color-bluish);
  }

  &--warning {
    @include button($color: $color-sun-yellow);
  }

  &--transparent {
    @include button($color: transparent);
  }

  &--error {
    @include button($color: $color-subsegment-agriculture);
  }

  &--delete {
    @include button($color: $color-subsegment-agriculture);
  }

  &--download {
    @include button-icon-reverse();
  }

  &--lg {
    @include button();
    font-size: $ks-font-size-7;
    font-weight: $font-weight-bold;
    padding: $ks-spacer-4;

    .icon {
      font-size: $ks-font-size-7;
    }
  }
}

