@use '../../abstracts' as *;

.ks-checkout-order-item {
  display: grid;
  grid-gap: $ks-spacer-4;
  grid-row-gap: 0;
  grid-template-columns: 96px 3fr 1fr 1.5fr 0.75fr 1.5fr;
  grid-template-rows: repeat(4, auto);
  margin-block: $ks-spacer-3;
  padding-block-end: $ks-spacer-3;
  padding-inline: $ks-spacer-1;
  padding-inline-end: $ks-spacer-8;

  @include respond-below(lg) {
    padding-inline-end: $ks-spacer-4;
  }

  @include respond-below(md) {
    padding-inline-end: $ks-spacer-3;
  }

  &s {
    margin-block: $ks-spacer-3;
  }

  &__image {
    display: flex;
    grid-area: 1 / 1 / -1 / 2;
  }

  &__description {
    grid-area: 1 / 2 / 2 / 3;
  }

  &__code {
    grid-area: 2 / 2 / 3 / 3;
  }

  &__chips {
    grid-area: 3 / 2 / 4 / 4;
  }

  &__name {
    font-weight: $font-weight-bold;
  }

  &__price {
    &--per-unit {
      grid-area: 1 / 4 / 3 / span 1;
      place-self: center flex-end;
    }

    &--total {
      font-size: $ks-font-size-6;
      font-weight: $font-weight-bold;
      grid-area: 1 / 6 / 3 / span 1;
      place-self: center flex-end;
    }
  }

  &__quantity {
    display: flex;
    flex-direction: column;
    grid-area: 1 / 5 / 3 / span 1;
    place-self: center flex-end;
  }

  &__pickup-location {
    align-items: center;
    display: flex;
    gap: $ks-spacer-2;
    grid-area: 1 / 3 / 3 / span 1;
    place-self: center flex-start;
  }
}

@container orderDaysContainer (max-inline-size: 950px) {
  .ks-checkout-order-item {
    grid-column-gap: $ks-spacer-3;
    grid: repeat(7, min-content) / 96px 1fr 46px;
    grid-row-gap: 4px;

    & > div:nth-child(n + 5) {
      margin-block-end: $ks-spacer-3;
    }
  ;

    &__image {
      display: flex;
      grid-area: 1 / 1 / 7 / 2;
    }

    &__description {
      grid-area: 1 / 2 / 2 / 3;
    }

    &__code {
      grid-area: 2 / 2 / 3 / 3;
    }

    &__chips {
      grid-area: 3 / 2 / 4 / 3;
      margin-bottom: $ks-spacer-5;
    }

    &__price {
      &--per-unit {
        grid-area: 5 / 2 / span 1 / span 1;
        place-self: center flex-start;
      }

      &--total {
        grid-area: 7 / 2 / span 1 / span 1;
        place-self: flex-start;
      }
    }

    &__quantity {
      grid-area: 6 / 2 / span 1 / span 1;
      place-self: flex-start;
    }

    &__pickup-location {
      grid-area: 4 / 2 / span 1 / span 1;
    }
  }
}
