@use '../../../abstracts' as *;

.ks-delivery-list {
  container-name: deliveryList;
  container-type: inline-size;

  &-wrapper {
    margin-block: $ks-spacer-6;
  }

  &-entry {
    $entry: &;
    margin-block: $ks-spacer-4;

    &:is(&:last-child) {
      #{$entry}-items {
        border-bottom: 1px solid $color-grey;
      }
    }

    &__information-header {
      background-color: $color-lighter-grey;
      border-top: 1px solid $color-grey;
      color: $color-brand-primary;
      display: grid;
      font-weight: $font-weight-bold;
      grid: auto-flow / minmax(100px, max-content) max-content 1fr $ks-spacer-3;
      grid-gap: $ks-spacer-8;
      padding: calc($ks-spacer-1 + $ks-spacer-2) $ks-spacer-5;
      place-items: center flex-start;
    }

    &__number-of-deliveries {
      span {
        @include badge($color: $color-brand-primary, $border: 2px solid $color-brand-primary, $position: inital, $font-size: 14px, $width: 24px)
      }
    }

    &__delivery-date {
      padding-block-start: $ks-spacer-1;
    }

    &__delete {
      color: $color-brand-primary;
      cursor: pointer;

      button {
        @include button-icon();
        background-color: transparent;
      }
    }

    &__content {
      background-color: $color-lighter-grey;
      padding: $ks-spacer-3;
    }
  }

  &-add-delivery {
    background-color: $color-lighter-grey;
    border: 2px solid $color-light-grey;
    cursor: pointer;
    display: grid;
    grid: auto-flow / minmax(100px, max-content) 1fr;
    grid-gap: $ks-spacer-4;
    transition: border-color 125ms ease;

    $this: &;

    &__label {
      color: $color-brand-primary;
      font-weight: $font-weight-bold;
      place-self: center flex-start;
    }

    &__plus {
      color: $color-grey;
      font-size: $ks-font-size-7;
      margin-block-end: $ks-spacer-2;
      place-self: center;
      transition: color 100ms ease;
    }

    &:hover {
      border-color: $color-brand-primary;

      #{$this}__plus {
        color: $color-brand-primary
      }
    }
  }
}

.ks-delivery-shared-information {
  margin-block-end: $ks-spacer-6;

  &__top-border {
    background-color: $color-brand-primary;
    min-height: $ks-spacer-4;
  }

  &__header {
    background-color: $color-lighter-grey;
    display: grid;
    grid: auto-flow / minmax(70px, max-content) repeat(2, 1.5fr) repeat(2, 1fr) minmax(34px, max-content);
    grid-gap: $ks-spacer-8;
    padding: $ks-spacer-3;

    &-item {
      &-label {
        color: $color-dark-grey;
        text-transform: uppercase;
      }

      &-value {
        color: $color-black;
        font-weight: $font-weight-bold;
      }

      &--messages {
        grid-column: -2 / span 1;
      }
    }
  }
}

@container deliveryList (max-inline-size: 1004px) {
  .ks-delivery-shared-information {
    &__header {
      grid: auto-flow/minmax(50px, max-content) repeat(2, 1fr) minmax(34px, max-content);
      grid-column-gap: $ks-spacer-4;
      grid-row-gap: $ks-spacer-2;

      &-item:nth-of-type(3) {
        grid-area: 2 / 2 / 3 / 3;
      }

      &-item:nth-of-type(4) {
        grid-area: 2 / 3 / 3 / 4;
      }
    }
  }

  .ks-delivery-list-entry {
    &__information-header {
      grid: auto-flow/minmax(50px, max-content) minmax(115px, max-content) 1fr 16px;
      grid-gap: $ks-spacer-4;
    }

    &__title {
      display: none;
    }
  }
}

