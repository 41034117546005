@use '../../../abstracts' as *;

.ks-delivery-list-entry-items {
  &-form {
    position: relative;
  }
}

.ks-delivery-list-entry-item {
  display: grid;
  grid-gap: $ks-spacer-4;
  grid-row-gap: 0;
  grid-template-columns: 96px 1.5fr repeat(4, 1fr) 46px;
  grid-template-rows: repeat(4, auto);
  margin-block: $ks-spacer-3;
  padding: $ks-spacer-1;

  &__image {
    display: flex;
    grid-area: 1 / 1 / -1 / 2;
  }

  &__description {
    grid-area: 1 / 2 / 2 / 3;
  }

  &__code {
    grid-area: 2 / 2 / 3 / 3;
  }

  &__chips {
    grid-area: 3 / 2 / 4 / span 3;
  }

  &__pickup-location {
    align-items: center;
    color: $color-dark-grey;
    display: flex;
    gap: $ks-spacer-2;
    grid-area: 1 / 3 / 2 / span 1;
  }

  &__name {
    font-weight: $font-weight-bold;
  }

  &__price {
    &--per-unit {
      grid-area: 1 / 4 / -1 / span 1;
      place-self: flex-start center;
    }

    &--total {
      font-size: $ks-font-size-6;
      font-weight: $font-weight-bold;
      grid-area: 1 / 6 / -1 / span 1;
      place-self: flex-start center;
    }
  }

  &__quantity {
    align-self: center;
    display: inline-flex;
    flex-direction: column;
    grid-area: 1 / 5 / -1 / span 1;
    place-self: flex-start;
    z-index: 0; // quantity picker is made with a shadow dom so we must set the z-index to prevent overlaying over other elements
  }

  &__messages {
    grid-area: 1 / 7 / -1 / span 1;
  }
}

@container deliveryList (max-inline-size: 950px) {
  .ks-delivery-list-entry-item {
    grid-column-gap: $ks-spacer-3;
    grid: repeat(7, min-content) / 96px 1fr 46px;
    grid-row-gap: 4px;

    &__image {
      display: flex;
      grid-area: 1 / 1 / -1 / 2;
    }

    &__description {
      grid-area: 1 / 2 / 2 / 3;
    }

    &__code {
      grid-area: 2 / 2 / 3 / 3;
    }

    &__chips {
      grid-area: 4 / 2 / span 1 / 3;
      margin-block: $ks-spacer-3;
    }

    &__pickup-location {
      grid-area: 3 / 2 / span 1 / span 1;
    }

    &__price {
      &--per-unit {
        grid-area: 5 / 2 / span 1 / 3;
        place-self: center flex-start;
      }

      &--total {
        grid-area: 7 / 2 / span 1 / 3;
        place-self: flex-start;
      }
    }

    &__quantity {
      grid-area: 6 / 2 / span 1 / 3;
      margin-block: $ks-spacer-2;
      place-self: flex-start;
    }

    &__messages {
      grid-area: 1 / 3 / -1 / 4;
    }
  }
}

.loading-because-deliveryTablesForm {
  .ks-delivery-list-entry-items {
    @include loadingState();
  }
}
