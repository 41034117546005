@use '../abstracts' as *;

.ks-card {
  align-items: center;
  border: 1px solid $color-border;
  display: flex;
  padding: $ks-spacer-3 $ks-spacer-4 + $ks-spacer-1;
  position: relative;

  @media print {
    page-break-inside: avoid;
  }

  :is(span, p) {
    line-height: 1.5rem;
    margin: initial;
  }

  &-actions {
    position: absolute;
    right: $ks-spacer-3;
    top: $ks-spacer-2;
    color: $color-brand-primary;
    font-weight: $font-weight-bold;

    button {
      @include button-icon()
    }
  }

  &__label {
    background-color: $color-white;
    color: $color-grey;
    font-size: $ks-font-size-3;
    left: 18px;
    padding: $ks-spacer-1 $ks-spacer-2;
    position: absolute;
    top: -$ks-spacer-3;
  }

  &__content {
    width: 100%;
  }

  &--in-modal {
    margin-block-end: $ks-spacer-4;
    cursor:pointer;
  }
}
